import React, { useState } from 'react';

export interface AuthStateUser {
  id: string;
  email: string;
  firstName: undefined | string;
  lastName: undefined | string;
  actorId: string;
  subscription: 'active' | 'deactive' | 'deactive_pending' | 'trial' | 'trial_expired' | undefined;
}

export interface AuthState {
  user: AuthStateUser | null;
  isUserLoggedIn: boolean;
  subscription: any;
}

interface AuthContext {
  auth: AuthState;
  setAuthData: (update: Partial<AuthState>) => void;
}

const initialAuthState: AuthState = {
  user: null,
  isUserLoggedIn: false,
  subscription: null,
};

export const AuthContext = React.createContext<AuthContext>({ auth: initialAuthState, setAuthData: () => {} });

interface Props {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: Props) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  function setAuthData(update: Partial<AuthState>) {
    setAuthState((current) => ({ ...current, ...update }));
  }

  const authValue = {
    setAuthData,
    auth: authState,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
