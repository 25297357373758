import Api from './Api';

interface RegisterCredentials {
  email: string;
  password: string;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface ResetPayload {
  email: string;
}

interface SetNewPasswordPayload {
  token: string;
  password: string;
}
const service = {
  register(credentials: RegisterCredentials) {
    return Api().post('register', credentials);
  },
  login(credentials: LoginCredentials) {
    return Api().post('login', credentials);
  },
  reset(payload: ResetPayload) {
    return Api().post('reset', payload);
  },
  setNewPassword(payload: SetNewPasswordPayload) {
    return Api().post('setnewpassword', payload);
  },
};

export default service;
