import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './animations.css';
import './scrollbar.css';
import AuthContextProvider from './AuthContext';
import RootApp from './RootApp';

ReactDOM.render(
  <AuthContextProvider>
    <RootApp />
  </AuthContextProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();

// const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

// const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// FOR CYPRESS *************************
// expose store when run in Cypress
// if (window.Cypress) {
//   window.store = store;
// }
