import decode from 'jwt-decode'

export function getTokenExpirationDate(token) {
  const decoded = decode(token)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp)
  return date

  // return new Date().setDate(new Date().getDate() + 1);

}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token)
  const offsetSeconds = 0
  if (date === null) {
    return false
  }
  return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)))
}

export function getTokenDetails(token) {
  const isExpired = isTokenExpired(token)
  const tokenData = decode(token)
  const expiration = getTokenExpirationDate(token)
  return { tokenData, isExpired, expiration }
}