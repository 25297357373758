import { useEffect } from 'react';
import Site from './containers/Site/Site';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { getTokenDetails } from './helpers/jwtHelpers';
import useAuth from './helpers/useAuth';
import FeaturesPage from './containers/Site/Pages/Features';
import LearnPage from './containers/Site/Pages/LearnPage';
import Login from './containers/Site/Pages/Login';
import SignUp from './containers/Site/Pages/SignUp';
import ResetPassword from './containers/Site/Pages/ResetPassword';
import NewPasswordPage from './containers/Site/Pages/NewPassword';
import Oops from './containers/Site/Pages/Oops';
import Terms from './containers/Site/Pages/Terms';
import Beta from './containers/Site/Pages/Beta';
import Privacy from './containers/Site/Pages/Privacy';
import CookiePolicy from './containers/Site/Pages/CookiePolicy';
import PricingPage from './containers/Site/Pages/Pricing/PricingPage';
import ReleasesPage from './containers/Site/Pages/ReleasesPage';
import SignUpForBetaPage from './containers/Site/Pages/SignUpForBeta';
import SubscribeSuccess from './containers/Site/Pages/SubscribeSuccess';
import SubscribeCancel from './containers/Site/Pages/SubscribeCancel';
import Landing from './containers/Site/Landing/Landing';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Site />,
      children: [
        {
          path: '/',
          element: <Landing />,
        },
        {
          path: 'features',
          element: <FeaturesPage scrollTo={(p) => {}} />,
        },
        {
          path: 'learn',
          element: <LearnPage scrollTo={(p) => {}} />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'signup',
          element: <SignUp />,
        },
        {
          path: 'reset',
          element: <ResetPassword />,
        },
        {
          path: 'newpassword',
          element: <NewPasswordPage />,
        },
        {
          path: 'oops',
          element: <Oops />,
        },
        {
          path: 'terms',
          element: <Terms />,
        },
        {
          path: 'beta',
          element: <Beta />,
        },
        {
          path: 'privacy',
          element: <Privacy />,
        },
        {
          path: 'cookiepolicy',
          element: <CookiePolicy />,
        },
        {
          path: 'pricing',
          element: <PricingPage />,
        },
        {
          path: 'releases',
          element: <ReleasesPage />,
        },
        {
          path: 'betasignup',
          element: <SignUpForBetaPage />,
        },
        {
          path: 'subscribeSuccess',
          element: <SubscribeSuccess />,
        },
        {
          path: 'subscribeCancel',
          element: <SubscribeCancel />,
        },
      ],
    },
  ],
  {}
);

interface Props {
  locale: string;
  messages: any;
  onLogin: Function;
  id: string | undefined;
}

const RootApp = (props: Props) => {
  const auth = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const { isExpired, tokenData } = getTokenDetails(token);
      if (!isExpired) {
        // if (props.id) {
        // First sanity check - is the user already logged in and token is valid. Should not end up here.
        // history.push({ pathname: '/app' });
        // window.location.href = `${import.meta.env.VITE_SITE_URL}}/app`;
        // }
        console.log('there is a token!');
        auth.auth.setAuthData({
          isUserLoggedIn: true,
          user: {
            id: tokenData.id,
            actorId: tokenData.actorId,
            firstName: 'FETCH-ME-FROM-API',
            lastName: 'FETCH-ME-FROM-API',
            email: 'FETCH-ME-FROM-API',
            subscription: undefined,
          },
        });
      }
    }
  }, []);

  console.log('RootApp render');
  const isAuthenticated = props.id ? true : false;

  return <RouterProvider router={router} />;
};

export default RootApp;

/**
 *
 * this should be cleaned up, which props go where.
 */
// function PrivateRoute({
//   component: Component,
//   isAuthenticated,
//   path,
//   ...rest
// }: {
//   component: any;
//   isAuthenticated: boolean;
//   path: string;
// }) {
//   if (!isAuthenticated) {
//     // logger.log('User not authenticated. Redirecting to landing page.');
//   }

//   return (
//     <Route
//       {...rest}
//       path={path}
//       render={(
//         props // this props comes from the Route
//       ) =>
//         isAuthenticated ? (
//           <Component {...props} {...rest} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/',
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

// interface CompState {
//   locale: string;
//   messages: any;
// }

// const mapStateToProps = (state: RootState) => {
//   return {
//     id: state.auth.user?.id,
//   };
// };

// const mapDisparchToProps = (dispatch: Function) => {
//   return {
//     onLogin: (payload: any) => dispatch(login(payload)),
//   };
// };

// export default connect(mapStateToProps, mapDisparchToProps)(RootApp);

// changeToLocale = (locale: string) => {
//   LocalesService.getByLocaleId(locale)
//     .then((response) => {
//       if (response.status >= 400) {
//         throw new Error('Unsuccesful locale request.');
//       }
//       return response.data;
//     })
//     .then((messages) => {
//       this.setState({
//         locale: locale,
//         messages: messages,
//       });
//     });
// };

// const foo = {
//   changeToLocale: this.changeToLocale,
//   locale: this.state.locale,
// };

// const LazyApp = lazy(() => import('./containers/App/App'));
